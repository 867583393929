import React from 'react'
import { Container, Layout, SEO } from 'components/common'
import { Footer } from 'components/theme'
import { legalName, acroynm } from 'data/config'

const Index = () => (
  <Layout>
    <SEO title={legalName} />
    <header className="site-header">
      <div className="school-name">
        <Container>
          <h1>{legalName}</h1>
        </Container>
      </div>
      <div className="school-logo">
        <Container>
          <div className="logo">{acroynm}</div>
        </Container>
      </div>
    </header>
    <main role="main" className="wue" />
    <Footer />
  </Layout>
)
export default Index
